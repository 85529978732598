import React from "react"
import styled, { css } from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby" //import navigate from gatsby

import globals from "./globals"
import { Container, H2, P } from "./styledcomponents"

import Button from "./button"
import Icons from "./icons"

const subColor = globals.color.darkShades
const mainColor = "#666"

const Icon = styled(Icons)`
  background-color: ${globals.color.main}50;
  height: 1.3em;
  margin-right: 5px;
  float: left;
`

const shrinkLabelStyles = css`
  ${(props) =>
    props.name !== "privacy"
      ? `font-size: 12px;  color: ${mainColor};  left: 10px; top: -14px;`
      : undefined}
`

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 80%;
  max-width: ${globals.media.tablet};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyItems && `justify-items: ${props.justifyItems}`};
  @media (max-width: ${globals.media.tablet}) {
    ${(props) =>
      props.gridTemplateColumnsTablet &&
      `grid-template-columns: ${props.gridTemplateColumnsTablet}`};
  }
  justify-content: space-between;
  height: 30em;
`

const GroupContainer = styled.div`
  position: relative;
  padding: 0 10px;
  margin: 0;
  text-align: left;
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  input[type="checkbox"] {
    position: absolute;
    left: -360%;
    height: 2em;
    width: 400%;
  }
  width: 100%;
`

const FormInputContainer = styled.input`
  background: none;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;

  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${(props) => (props.type === "checkbox" ? undefined : shrinkLabelStyles)}
  }
`

const FormTextareaContainer = styled.textarea`
  background: none;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;
  height: 7em;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`

const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  ${(props) => props.type !== "checkbox" && "position: absolute"};
  pointer-events: none;
  left: ${(props) => (props.type !== "checkbox" ? "20px" : "50px")};
  top: 10px;
  text-align: left;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`

const SignUpButton = styled(Button)`
  width: 100%;
`

const FormInput = ({ handleChange, label, type, name, ...props }) => (
  <GroupContainer>
    {type === "textarea" ? (
      <FormTextareaContainer
        onChange={handleChange}
        name={name}
        id={name}
        {...props}
      />
    ) : (
      <FormInputContainer
        onChange={handleChange}
        type={type}
        name={name}
        id={name}
        {...props}
      />
    )}
    {type === "checkbox" ? (
      <Icon icon={props.value ? "squareCheck" : "square"} />
    ) : undefined}

    {label ? (
      <FormInputLabel
        className={type !== "checkbox" && props.value.length ? "shrink" : ""}
        type={type}
        for={name}
      >
        {label}
      </FormInputLabel>
    ) : null}
  </GroupContainer>
)

export class SignUpCovid extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      error: "",
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    addToMailchimp(this.state.email, {
      FNAME: this.state.firstName,
      LNAME: this.state.lastName,
      "group[5228]": "64",
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        if (data.result === "success") {
          navigate("/yoga-covid-erholung-danke")
        } else {
          let msg = <div dangerouslySetInnerHTML={{ __html: data.msg }} />
          this.setState({
            error: msg,
          })
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
      })
  }

  handleChange = (event) => {
    const { value, name } = event.target
    this.setState({ [name]: value })
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  render() {
    return (
      <Container margin="2em 0">
        <H2 center>
          Jetzt zum kostenlosen Yogakurs zur Erholung von Covid-19 anmelden!
        </H2>
        <Form
          name="signUpYogaCorona"
          method="POST"
          id="form"
          onSubmit={this.handleSubmit}
          maxWidth={globals.media.flexBox1}
          alignItems="center"
          justifyItems="center"
        >
          <FormInput
            name="firstName"
            type="text"
            value={this.state.firstName}
            onChange={this.handleChange}
            label="Vorname"
            required
          />
          <FormInput
            name="lastName"
            type="text"
            value={this.state.lastName}
            onChange={this.handleChange}
            label="Nachname"
            required
          />

          <FormInput
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
            label="Email"
            required
          />

          <FormInput
            name="privacy"
            type="checkbox"
            value={this.state.privacy}
            onChange={this.handleCheckbox}
            label="TLA möchte Ihnen gelegentlich Neuigkeiten, Gesundheits-Tipps, und Angebote senden. Bitte bestätigen Sie, dass Sie in unsere Mailingliste aufgenommen werden wollen. Wir hassen SPAM und Sie können sich jederzeit abmelden."
            fullWidth
            required
            columns="1/5"
            columnsTablet="1/2"
          />
          <SignUpButton center type="submit" margin="0" width="100%">
            Zum Yoga zur Erholung von Corona anmelden
          </SignUpButton>
          <input type="hidden" name="form-name" value="signUpYogaCorona" />
        </Form>
        {this.state.error && <P>ERROR: {this.state.error}</P>}
      </Container>
    )
  }
}
