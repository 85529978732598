import React from "react"
import { graphql } from "gatsby"

import { HeroStatic } from "../components/hero"
import ValueBox from "../components/valuebox"
import ContentAndPicture from "../components/content-and-picture"
import LeaningImage from "../components/leaningImage"
import styled from "styled-components"

import Layout from "../components/layout"

import { SignUpCovid } from "../components/signUpForm"

import { Container, P, Li } from "../components/styledcomponents"
import SEO from "../components/seo"

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const YogaForCovid = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Kostenloses Yoga zur Unterstützung der COVID-Erholung"
        description="Studien zufolge kann Yoga helfen die Symptome, die während der Erholung von Covid-19 auftreten, zu lindern. In diesem Kurs helfen wir dir dabei!"
      />
      <HeroStatic
        title="Kostenloses Yoga zur Unterstützung der COVID-Erholung"
        id="1"
        img={data.hero}
        imgAlt="Yoga in München"
      />
      <ValueBox
        values={[
          {
            id: 1,
            value: "Ab Mittwoch, 17.03.2021",
          },
          { id: 2, value: "6 Termine" },
          { id: 3, value: "11:00-11:30" },
          { id: 4, value: "Online via Zoom" },
        ]}
      />
      <Container backgroundColor="white">
        <SignUpCovid />

        <ContentAndPicture
          h2="Personen, die sich von Covid-19 erholen, können unter einer
          Kombination der folgenden Symptome leiden:"
          description={
            <>
              <ul>
                <Li>Müdigkeit</Li>
                <Li>erhöhte Unruhe</Li>
                <Li>Atembeschwerden</Li>
                <Li>Schlafstörungen</Li>
                <Li>Angstgefühle</Li>
                <Li>Verlust des Selbstvertrauens</Li>
              </ul>
              <P>
                Studien sowie haben gezeigt, dass Yoga helfen kann, diese
                Schwierigkeiten zu lindern. Daher haben wir einen Yogakurs
                organisiert, der basierend auf diesen Studien und unser eigenen
                Erfahrung nach unserer Erkrankung hilft diese Symptome zu
                lindern.
              </P>
            </>
          }
          imgLeft={data.pranajama}
        />
        <ContentAndPicture
          h2="Was wir während diesen 30 Min tun werden:"
          description={
            <>
              <ul>
                <Li>
                  Pranayama (Atemübungen): Verbessert Lungenkapazität,
                  Sauerstoffaufnahme und aktiviert das parasymphatische
                  Nervensystem.
                </Li>
                <Li>
                  Gelenke sanft bewegen: Hilft die Durchblutung zu verbessern
                  und Körperschmerzen zu reduzieren.
                </Li>
                <Li>
                  Sanfte Asanas: Helfen die Atemwege zu öffnen undden Kreislauf
                  im ganzen Körper in Gang zu bringen, wodurch Du Dich besser
                  fühlst.
                </Li>
              </ul>
              <Flex>
                <LeaningImage image={data.breath} />
                <P>
                  Damit Du nicht bis zum Kursstart warten musst schicken wir Dir
                  bei der Anmeldung unseren Guide mit Atemuebungen for free!
                </P>
              </Flex>
            </>
          }
          imgRight={data.yin}
        />
        <SignUpCovid />
      </Container>
    </Layout>
  )
}
export const query = graphql`{
  hero: file(relativePath: {eq: "Yoga/Reverse-Namaste.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  pranajama: file(relativePath: {eq: "Yoga/Yoga-Muenchen-Nadi-Shodana.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  yin: file(relativePath: {eq: "Yoga/Yoga-Muenchen-Yin-Yoga.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
  breath: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
      )
    }
  }
}
`
export default YogaForCovid
